<template>
  <div class="login">
    <div class="form-login-content">
      <p class="title">
        Veuillez vous connecter pour continuer
      </p>
      <form @submit.prevent="Connexion">
        <div class="body">
          <input
            type="email"
            id="email"
            v-model="user.email"
            required
            placeholder="E-mail"
          />
        </div>
        <div class="body">
          <input
            type="password"
            id="password"
            placeholder="Mot depasse"
            v-model="user.password"
            required
          />
        </div>
        <div class="action">
          <b-button type="submit" variant="primary">
            Connexion
            <div v-if="getloginLoding" class="chargement">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </b-button>
        </div>
      </form>
    </div>
    <div v-if="getloginError" class="error">
      {{ getloginError }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      user: {
        email: null,
        password: null
      }
    };
  },
  methods: {
    ...mapActions(['login']),
    Connexion() {
      this.login(this.user);
    }
  },
  computed: {
    ...mapGetters(['getloginLoding', 'getloginError'])
  }
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-position: 50% top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/close-up-messy-table-with-co-workers-background.png');

  .form-login-content {
    width: fit-content;
    padding: 30px 20px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 3px 6px #f2f2f2;
    width: 44%;
    margin-top: 3%;
    margin-right: 4%;
    .title {
      color: #707070;
      text-align: center;
      font-size: 23;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    form {
      .body {
        margin: 10px auto;
        #email,
        #password {
          border-radius: 39px;
          border: none;
          padding: 10px 19px;
          background-color: #f9f9f9;
          &::placeholder {
            color: #5d5d5d;
            font-size: 12px;
          }
          &:focus-visible {
            outline: none;
          }
        }
        input {
          font: inherit;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .action {
        margin: 20px 0;
        button {
          width: 100%;
          border-radius: 39px;
          display: flex;
          justify-content: center;
          background-color: #4d4bac;
          padding: 11px 20px;
          border: 0;
          font-size: 14px;
          box-sizing: border-box;
          cursor: pointer;
          .chargement {
            margin-left: 10px;
            font-size: 10px;
            .spinner-border {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
  }
  .error {
    position: absolute;
    width: 100%;
    color: #ff0000;
    font-size: 12px;
    text-align: center;
    bottom: 18%;
    right: 3%;
  }
}
</style>
